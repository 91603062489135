import axios from 'axios'
// import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/kintaiwatch/WP'

const state = {
  list: {},
  name: '',
  staffId: '',
  ym: '',
  total: ''
}

const getters = {
  list: (state) => state.list,
  name: (state) => state.name,
  staffId: (state) => state.staffId,
  ym: (state) => state.ym,
  total: (state) => state.total
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  name (state, name) {
    state.name = name
  },
  staffId (state, staffId) {
    state.staffId = staffId
  },
  ym (state, ym) {
    state.ym = ym
  },
  total (state, total) {
    state.total = total
  }
}

const actions = {
  //
  // 日別ログ取得
  loadDailyLog ({ rootGetters, commit }, data) {
    if (rootGetters['Auth/token'] !== '' && rootGetters['Auth/token'] !== undefined) {
      return new Promise((resolve) => {
        commit('processing', true, { root: true })
        axios
          .post('/wp-json/kw2/v1/log/daily/', data)
          .then((response) => {
            console.log(response.data)
            commit('list', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            commit('processing', false, { root: true })
          })
      })
    }
  },
  //
  // 月別ログ取得
  loadMonthlyLog ({ rootGetters, commit }, yymm) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      commit('ym', yymm)
      axios
        .post('/wp-json/kw2/v1/log/monthly/', {
          slug: rootGetters.slug,
          yymm: yymm
        })
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ別月別ログ取得
  loadMonthlyLogOfStaff ({ rootGetters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/log/staff/monthly/', {
          slug: rootGetters.slug,
          staffid: data.id,
          yymm: data.yymm
        })
        .then((response) => {
          console.log(response.data)
          commit('list', response.data.list)
          commit('name', response.data.name)
          commit('staffId', response.data.staffId)
          commit('ym', response.data.ym)
          commit('total', response.data.monthlyTotal)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ新規追加
  addNew ({ rootGetters, getters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/log/addnew/', {
          slug: rootGetters.slug,
          data: data
        })
        .then((response) => {
          // console.log(response.data)
          const found = getters.list.find(el => el.date === response.data.ymd)
          found.id = response.data.id
          found.log = {
            date: response.data.ymd,
            id: response.data.id,
            log: response.data.log,
            total: response.data.total
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 出勤記録追加
  addNewAttendance ({ rootGetters, getters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/attendance', {
          slug: rootGetters.slug,
          ymd: rootGetters.ymd,
          staffId: data.staffId,
          cat: data.cat,
          IN: data.IN,
          OUT: data.OUT
        })
        .then((response) => {
          console.log(response.data)
          const newList = getters.list
          newList.push(response.data)
          commit('list', newList)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ更新(日次)
  updateDailyLog ({ rootGetters, getters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/log/update/', {
          slug: rootGetters.slug,
          data: data
        })
        .then((response) => {
          console.log(response.data)
          const found = getters.list.find(el => el.id === data.id)
          found.total = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ更新(月次)
  updateMonthlyLog ({ rootGetters, getters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/log/update/monthly', {
          slug: rootGetters.slug,
          data: data
        })
        .then((response) => {
          console.log(response.data)
          const found = getters.list.find(el => el.id === data.data.id)
          found.log.total = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Log = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Log
