export default {
  data () {
    return {
      today: new Date(),
      exactToday: new Date(),
      YYMM: '',
      YYMMDD: ''
    }
  },
  mounted () {
    this.YYMM = this.getYYMMfromDate(this.today)
    this.YYMMDD = this.getYYMMDDfromDate(this.today)
  },
  methods: {
    // Date型からyyyy-mm
    getYYMMfromDate (date) {
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
    },
    // Date型からyyyy-mm-dd
    getYYMMDDfromDate (date) {
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    },
    //
    // 前月
    monthSetPrev () {
      this.today.setMonth(this.today.getMonth() - 1)
      this.today = new Date(this.today)
      this.YYMM = this.getYYMMfromDate(this.today)
    },
    //
    // 翌月
    monthSetNext () {
      this.today.setMonth(this.today.getMonth() + 1)
      this.today = new Date(this.today)
      this.YYMM = this.getYYMMfromDate(this.today)
    },
    //
    // 前日
    dateSetPrev () {
      this.today.setDate(this.today.getDate() - 1)
      this.today = new Date(this.today)
    },
    //
    // 翌日
    dateSetNext () {
      if (this.exactToday - this.today > 86400000) {
        this.today.setDate(this.today.getDate() + 1)
        this.today = new Date(this.today)
      }
    },
    //
    // csvダウンロード
    csvDownload (headerArr, contentArr, filename) {
      // const headerRow = {
      //   employeeName: '氏名',
      //   daysCount: '出勤日数',
      //   total_worktime: '累計労働時間',
      //   total_leagal_ovetime: '累計残業時間(法定内)',
      //   total_non_statutory_overtime: '累計残業時間(法定外)'
      // }
      // let csv = Object.values(headerRow).join() + '\r\n'
      let csv = headerArr.join() + '\r\n'

      // オブジェクトの中身を取り出してカンマ区切りにする
      for (const row of contentArr) {
        csv += row.join() + '\r\n'
      }

      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csv], { type: 'text/csv' })
      const objectUrl = URL.createObjectURL(blob)
      const downloadLink = document.createElement('a')
      downloadLink.download = `${filename}.csv`
      downloadLink.href = objectUrl
      downloadLink.click()
      downloadLink.remove()
    }
  },
  computed: {
    //
    // 年月
    monthStr () {
      return this.today.getFullYear() + '年' + (this.today.getMonth() + 1) + '月'
    },
    //
    // 日付
    monthDateStr () {
      const arr = ['日', '月', '火', '水', '木', '金', '土']
      // const no = this.today.getUTCDay()
      const no = this.today.getDay()
      return this.today.getFullYear() + '年' + (this.today.getMonth() + 1) + '月' + this.today.getDate() + '日' + '(' + arr[no] + ')'
    },
    //
    // 日付時刻から時刻のみ表示
    showHHMM () {
      return (datestr) => {
        const date = new Date(datestr)
        return !isNaN(date.getDate()) ? ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) : ''
      }
    },
    //
    // 日付時刻から時のみ表示
    showHH () {
      return (datestr) => {
        const date = new Date(datestr)
        return date ? parseInt(date.getHours()) : ''
      }
    },
    //
    // hh:mm形式の時間の差
    diffTime () {
      return (t1, t2) => {
        t1 = t1.split(':')
        t2 = t2.split(':')
        const diffMin = (parseInt(t2[0]) - parseInt(t1[0])) * 60 + (parseInt(t2[1]) - parseInt(t1[1]))
        return diffMin
      }
    },
    //
    // hh:mm形式の時間の差(%)
    diffTimePercent () {
      return (t1, t2) => {
        t1 = t1.split(':')
        t2 = t2.split(':')
        const diffMin = (parseInt(t2[0]) - parseInt(t1[0])) * 60 + (parseInt(t2[1]) - parseInt(t1[1]))
        return (diffMin / 1440) * 100
      }
    },
    //
    // 分からhh:MM表示
    minToHHMM () {
      return (num) => {
        const hour = Math.floor(num / 60)
        const min = num % 60
        return num ? '' + hour + ':' + ('0' + min).slice(-2) : ''
      }
    },
    //
    // YY-mmから年月表示
    yymmToStr () {
      return (yymm) => {
        const ym = yymm.split('-')
        return ym[0] + '年' + parseInt(ym[1]) + '月'
      }
    },
    //
    // yyyy-mm-dd形式をyyyy年mm月dd日に変換
    formatYMD () {
      return (ymd) => {
        if (ymd && ymd !== undefined) {
          ymd = ymd.split('-')
          return ymd[0] && ymd[1] && ymd[2] ? ymd[0] + '年' + parseInt(ymd[1]) + '月' + parseInt(ymd[2]) + '日' : ''
        } else {
          return ''
        }
      }
    }
  },
  watch: {
    today: {
      handler (newValue) {
        this.YYMM = this.getYYMMfromDate(newValue)
        this.YYMMDD = this.getYYMMDDfromDate(newValue)
      }
    },
    YYMMDD: {
      handler (newValue) {
        this.today = new Date(newValue)
        this.$store.commit('ymd', newValue)
        if (this.$route.name === 'OwnerDashboard') {
          this.$store.dispatch('Log/loadDailyLog', {
            slug: this.$store.getters.slug,
            ymd: newValue
          })
        }
      }
    },
    YYMM: {
      handler (newValue) {
        if (this.$route.name === 'AttendanceLog') {
          this.$store.dispatch('Log/loadMonthlyLog', newValue)
        }
        if (this.$route.name === 'StaffDetail') {
          this.$store.dispatch('Log/loadMonthlyLogOfStaff', {
            id: this.$route.params.uuid,
            yymm: newValue
          })
        }
      }
    }
  }
}
