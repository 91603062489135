import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import OwnerDashboard from '../views/OwnerDashboard.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      // store.dispatch('clearData')
      if (store.getters['Auth/token'] === null) {
        next({ name: 'login' })
      } else {
        console.log('now im here')
        switch (store.getters['Auth/capability']) {
          case 'superadmin':
            next({ name: 'AdminDashboard' })
            break
          case 'owner':
            next({ name: 'OwnerDashboard', params: { dom: store.getters.slug } })
            break
          default:
            next({ name: 'Forbidden' })
        }
      }
    }
  },
  {
    // 社労士管理画面
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import(/* webpackChunkName: "AdminDashboard" */ '../views/AdminDashboard.vue'),
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/:dom',
    name: 'OwnerDashboard',
    component: OwnerDashboard,
    beforeEnter: (to, from, next) => {
      store.commit('slug', store.getters.slug)
      next()
    }
  },
  {
    // 月次記録
    path: '/log',
    name: 'AttendanceLog',
    component: () => import(/* webpackChunkName: "AttendanceLog" */ '../views/AttendanceLog.vue'),
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/staff',
    children: [
      {
        // スタッフリスト
        path: '',
        name: 'StaffList',
        component: () => import(/* webpackChunkName: "StaffList" */ '../views/StaffList.vue'),
        beforeEnter: (to, from, next) => {
          next()
        }
      },
      {
        // スタッフ別勤務状況
        path: ':uuid',
        name: 'StaffDetail',
        component: () => import(/* webpackChunkName: "StaffDetail" */ '../views/StaffDetail.vue'),
        beforeEnter: (to, from, next) => {
          next()
        }
      }
    ]
  },
  {
    // QR
    path: '/QR',
    name: 'ShowQR',
    component: () => import(/* webpackChunkName: "ShowQR" */ '../views/ShowQR.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Owner/loadBranchList')
      next()
    }
  },
  {
    // ブランチ管理
    path: '/branch',
    name: 'branch',
    component: () => import(/* webpackChunkName: "branch" */ '../views/BranchView.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Owner/loadBranchList')
      next()
    }
  },
  {
    // 403
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "Forbidden" */ '../views/ForbiddenView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
