import axios from 'axios'
// import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/kintaiwatch/WP'

const state = {
  list: [],
  clientData: {}
}

const getters = {
  list: (state) => state.list,
  clientData: (state) => state.clientData
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  clientData (state, clientData) {
    state.clientData = clientData
  }
}

const actions = {
  //
  // Get client list
  async getList ({ getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/kw2/v1/admin/clientlist')
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // サービス停止
  async haltService ({ commit }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/admin/halt', id)
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // サービス再開
  async resumeService ({ commit }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/admin/resume', id)
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント追加
  async addClient ({ commit }, clientData) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/admin/client', clientData)
        .then((response) => {
          commit('clientData', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント完全削除
  async deleteCompletely ({ commit }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .delete('/wp-json/kw2/v1/admin/client/' + id)
        .then((response) => {
          // console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Admin = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Admin
