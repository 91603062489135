<template>
  <div id="shade">
    <div class="modal login">
      <h1>Kintai Watch勤怠管理</h1>
      <dl>
        <dt>ユーザー名</dt>
        <dd><input type="text" v-model="authData.username" placeholder="ユーザー名"></dd>
      </dl>
      <dl>
        <dt>パスワード</dt>
        <dd>
          <input
            type="password"
            v-model="authData.password"
            placeholder="パスワード"
            @keydown.enter="login"
          >
        </dd>
      </dl>
      <div class="center">
        <button @click="login">login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      authData: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    this.$store.commit('pageTitle', 'Login | Kintai Watch勤怠管理')
    this.$store.dispatch('clearData')
    if (this.$store.getters['Auth/authData'] !== null) {
      if (this.$store.getters['Auth/authData'].username && this.$store.getters['Auth/authData'].password) {
        this.authData = this.$store.getters['Auth/authData']
      }
    }
    this.$store.watch(
      (state, getters) => getters['Auth/token'],
      (newValue) => {
        this.$router.push('/')
      }
    )
  },
  methods: {
    login () {
      this.$store.commit('Auth/authData', this.authData)
      this.$store.dispatch('Auth/login')
    }
  }
}
</script>
