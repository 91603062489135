export default {
  data () {
    return {
      editMode: false,
      ymd: '',
      logId: '',
      nth: '',
      cat: '',
      slot: {
        IN: '',
        OUT: ''
      }
    }
  },
  mounted () {
  },
  methods: {
    //
    // 編集モードON
    activateEditMode (e) {
      this.ymd = ''
      this.logId = ''
      this.nth = ''
      this.slot = { IN: '', OUT: '' }
      const editlog = document.getElementById('editlog')
      editlog.style.top = `${e.y + 18}px`
      editlog.style.left = `${e.x - 45}px`
      this.editMode = true
    },
    //
    // 編集モードOFF
    deactivateEditMode () {
      this.ymd = ''
      this.logId = ''
      this.nth = ''
      this.slot = { IN: '', OUT: '' }
      this.editMode = false
      if (Object.keys(this.branchList).length > 0 && this.cat) {
        const found = this.branchList.find(el => el.term_id === this.cat)
        if (found) {
          found.newRow = {
            staffId: '',
            ymd: '',
            IN: '',
            OUT: ''
          }
        }
      }
      this.cat = ''
    },
    //
    // ログ新規追加
    addNewLog (row, e) {
      const theday = new Date(row.date ? row.date : this.$store.getters.ymd)
      const today = new Date()
      today.setHours(23)
      today.setMinutes(59)
      today.setSeconds(59)
      if (theday < today) {
        this.activateEditMode(e)
        this.logId = row.id
        if ('date' in row) {
          this.ymd = row.date
        }
      }
    },
    //
    // 出勤記録新規追加
    addNewAttendance (branch, e) {
      if (!branch.newRow.staffId) {
        return
      }
      this.cat = branch.term_id
      this.activateEditMode(e)
    },
    //
    // 既存ログを編集
    editExistingLog (data, e) {
      this.activateEditMode(e)
      this.logId = data.id
      this.nth = data.nth
      this.slot.IN = data.log.IN ? this.showHHMM(data.log.IN) : ''
      this.slot.OUT = data.log.OUT ? this.showHHMM(data.log.OUT) : ''
    },
    //
    // 変更適用(日次)
    setLogDaily () {
      if (!this.slot.IN && !this.slot.OUT) {
        this.deactivateEditMode()
        return
      }
      // 大小比較
      const strIN = this.slot.IN ? `${this.$store.getters.ymd} ${this.slot.IN}:00` : false
      const strOUT = this.slot.IN ? `${this.$store.getters.ymd} ${this.slot.OUT}:00` : false
      const timeIN = strIN ? new Date(strIN) : false
      const timeOUT = strOUT ? new Date(strOUT) : false
      if (timeIN && timeOUT) {
        if (timeIN.getTime() > timeOUT.getTime()) {
          alert('時間の指定が不正です')
          return
        }
      }
      if (this.logId) {
        // 既存ログ編集操作
        const found = this.list.find(el => el.id === this.logId)
        const timestampArr = []
        const logarr = found.log.filter((el, index) => index !== this.nth)
        logarr.forEach(el => {
          const IN = new Date(el.IN)
          const OUT = new Date(el.OUT)
          timestampArr.push({
            IN: IN.getTime(),
            OUT: OUT.getTime()
          })
        })
        const merged = []
        let IN = timeIN ? timeIN.getTime() : ''
        let OUT = timeIN ? timeOUT.getTime() : ''
        let add = true
        while (timestampArr.length > 0) {
          const row = timestampArr.pop()
          if (IN && OUT) {
            add = true
            if (IN <= row.IN && row.IN <= OUT && OUT <= row.OUT) {
              OUT = row.OUT
            } else if (row.OUT <= OUT && row.IN <= IN && IN <= row.OUT) {
              IN = row.IN
            } else if (IN <= row.IN && row.OUT < OUT) {
              // do nothing
            } else {
              merged.push(row)
            }
          } else {
            if (IN !== '') {
              if (row.IN <= IN && IN <= row.OUT) {
                add = false
              }
            } else if (OUT !== '') {
              if (row.IN <= OUT && OUT <= row.OUT) {
                add = false
              }
            }
            merged.push(row)
          }
        }
        if (add) {
          merged.push({ IN: IN, OUT: OUT })
        }
        merged.sort((a, b) =>
          a.IN > b.IN ? 1 : -1
        )
        merged.forEach(el => {
          el.IN = el.IN ? `${this.$store.getters.ymd} ${this.showHHMM(el.IN)}:00` : false
          el.OUT = el.OUT ? `${this.$store.getters.ymd} ${this.showHHMM(el.OUT)}:00` : false
        })
        found.log = merged
        this.$store.dispatch('Log/updateDailyLog', found)
      } else {
        // ログ新規追加操作
        const found = this.branchList.find(el => el.term_id === this.cat)
        found.newRow.IN = this.slot.IN
        found.newRow.OUT = this.slot.OUT
        found.newRow.cat = this.cat
        this.$store.dispatch('Log/addNewAttendance', found.newRow)
        found.newRow = {
          staffId: '',
          ymd: '',
          IN: '',
          OUT: ''
        }
      }
      this.deactivateEditMode()
    },
    //
    // 変更適用(月次)
    setLogMonthly (ymd) {
      if (!this.slot.IN && !this.slot.OUT) {
        this.deactivateEditMode()
        return
      }
      // 大小比較
      const strIN = this.slot.IN ? `${this.$store.getters.ymd} ${this.slot.IN}:00` : false
      const strOUT = this.slot.IN ? `${this.$store.getters.ymd} ${this.slot.OUT}:00` : false
      const timeIN = strIN ? new Date(strIN) : false
      const timeOUT = strOUT ? new Date(strOUT) : false
      if (timeIN && timeOUT) {
        if (timeIN.getTime() > timeOUT.getTime()) {
          alert('時間の指定が不正です')
          return
        }
      }
      if (this.logId) {
        const found = this.list.find(el => el.id === this.logId)
        const timestampArr = []
        const logarr = found.log.log.filter((el, index) => index !== this.nth)
        // console.log(logarr)
        logarr.forEach(el => {
          const IN = new Date(el.IN)
          const OUT = new Date(el.OUT)
          timestampArr.push({
            IN: IN.getTime(),
            OUT: OUT.getTime()
          })
        })
        const merged = []
        let IN = timeIN ? timeIN.getTime() : ''
        let OUT = timeIN ? timeOUT.getTime() : ''
        let add = true
        while (timestampArr.length > 0) {
          const row = timestampArr.pop()
          if (IN && OUT) {
            add = true
            if (IN <= row.IN && row.IN <= OUT && OUT <= row.OUT) {
              OUT = row.OUT
            } else if (row.OUT <= OUT && row.IN <= IN && IN <= row.OUT) {
              IN = row.IN
            } else if (IN <= row.IN && row.OUT < OUT) {
              // do nothing
            } else {
              merged.push(row)
            }
          } else {
            if (IN !== '') {
              if (row.IN <= IN && IN <= row.OUT) {
                add = false
              }
            } else if (OUT !== '') {
              if (row.IN <= OUT && OUT <= row.OUT) {
                add = false
              }
            }
            merged.push(row)
          }
        }
        if (add) {
          merged.push({ IN: IN, OUT: OUT })
        }
        merged.sort((a, b) =>
          a.IN > b.IN ? 1 : -1
        )
        merged.forEach(el => {
          el.IN = el.IN ? `${this.$store.getters.ymd} ${this.showHHMM(el.IN)}:00` : false
          el.OUT = el.OUT ? `${this.$store.getters.ymd} ${this.showHHMM(el.OUT)}:00` : false
        })
        found.log.log = merged
        this.$store.dispatch('Log/updateMonthlyLog', {
          uuid: this.$route.params.uuid,
          data: found
        })
      } else {
        // idが存在しなければ新規作成
        this.$store.dispatch('Log/addNew', {
          uuid: this.$route.params.uuid,
          ymd: this.ymd,
          IN: this.slot.IN,
          OUT: this.slot.OUT
        })
      }
      this.deactivateEditMode()
    },
    //
    // ログ削除(日次)
    deleteLogDaily () {
      if (confirm('ログを削除しますか？')) {
        const found = this.list.find(el => el.id === this.logId)
        const reducedArr = found.log.filter((el, index) => index !== this.nth)
        found.log = reducedArr
        this.$store.dispatch('Log/updateDailyLog', found)
        if (reducedArr.length === 0) {
          this.list = this.list.filter(el => el.id !== this.logId)
        }
        this.deactivateEditMode()
      }
    },
    //
    // ログ削除(月次)
    deleteLogMonthly () {
      if (confirm('ログを削除しますか？')) {
        const found = this.list.find(el => el.id === this.logId)
        const reducedArr = found.log.log.filter((el, index) => index !== this.nth)
        found.log.log = reducedArr
        this.$store.dispatch('Log/updateMonthlyLog', {
          data: found
        })
        if (reducedArr.length === 0) {
          this.list = this.list.filter(el => el.id !== this.logId)
        }
        this.deactivateEditMode()
      }
    }
  },
  computed: {
  },
  watch: {
    slot: {
      handler (newValue) {
        console.log(newValue)
      },
      deep: true
    },
    newRow: {
      handler (newValue) {
        console.log(newValue)
      },
      deep: true
    }
  }
}
