<template>
  <div class="home">
    home view
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('pageTitle', 'Home | Kintai Watch勤怠管理')
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
