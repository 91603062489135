<template>
  <div @click="deactivateEditMode">
    <DateSelect
      @reload="reload"
    />
    <section id="logwrap" :count="Object.keys(branchList).length">
      <div class="dailylog" v-for="branch in branchList" :key="branch.term_id" >
        <h2
          v-if="Object.keys(branchList).length > 1"
        >{{ branch.name }}</h2>
        <ul>
          <!--▼見出し行-->
          <li class="frontrow">
            <div class="name">社員名</div>
            <div class="timeline">
              <div class="grid">
                <span
                  v-for="(item, index) in 24" :key="index"
                >
                  <span v-if="(item - 1)%3 === 0">{{ item - 1 }}:00</span>
                  <span v-else></span>
                </span>
              </div>
            </div>
            <div class="total">労働時間</div>
          </li>
          <!--▼出勤リスト-->
          <li
            v-for="(item, rowNo) in attendanceList(parseInt(branch.term_id))"
            :key="rowNo"
            @click.stop="addNewLog(item, $event)"
          >
            <div class="name" @click.stop>{{ item.name }}</div>
            <div class="timeline">
              <div class="grid">
                <span v-for="(item, cellNo) in 24" :key="cellNo"></span>
              </div>
              <div
                v-for="(log, logNo) in item.log" :key="logNo"
                class="log"
                :class="{ fixed: log.OUT }"
                :style="{
                  left: diffTimePercent('0:00', showHHMM(log.IN)) + '%',
                  width: diffTimePercent(showHHMM(log.IN), showHHMM(log.OUT)) + '%'
                }"
                @click.stop="editExistingLog({
                  log: log,
                  id: item.id,
                  nth: logNo
                }, $event)"
              >
                <span class="in">
                  <span v-if="log.IN"><i class="fa-solid fa-circle-down"></i>{{ showHHMM(log.IN) }}出勤</span>
                </span>
                <span class="out">
                  <span v-if="log.OUT">退勤{{ showHHMM(log.OUT) }}<i class="fa-solid fa-circle-up"></i></span>
                </span>
              </div>
            </div>
            <div class="total">{{ minToHHMM(item.total) }}</div>
          </li>
          <!--▼勤務追加-->
          <li
            class="addrow"
            @click.stop="addNewAttendance(branch, $event)"
          >
            <div class="name">
              <select
                v-if="Object.keys(branchList).length > 1"
                v-model="branch.newRow.staffId"
                @click.stop
              >
                <option value="" disabled>勤務追加▼</option>
                <template v-for="cat in branchList" :key="cat.term_id">
                  <template v-if="Object.keys(cat.staffs).length > 0">
                    <hr>
                    <optgroup :label="cat.name">
                    <option
                      v-for="staff in reducedStaffList(cat.staffs)"
                      :key="staff.id"
                      :value="staff.id"
                    >{{ staff.name }}</option>
                    </optgroup>
                  </template>
                </template>
              </select>
              <select
                v-else
                v-model="branch.newRow.staffId"
                @click.stop
              >
                <option value="" disabled>勤務追加▼</option>
                <option
                  v-for="staff in staffList"
                  :key="staff.id"
                  :value="staff.id"
                >{{ staff.name }}</option>
              </select>
            </div>
            <div class="timeline">
              <div class="grid">
                <span v-for="(item, cellNo) in 24" :key="cellNo"></span>
              </div>
              <div
                class="log"
                :class="{ fixed: branch.newRow.OUT }"
                :style="{
                  left: diffTimePercent('0:00', showHHMM(branch.newRow.IN)) + '%',
                  width: diffTimePercent(showHHMM(branch.newRow.IN), showHHMM(branch.newRow.OUT)) + '%'
                }"
              >
                <span class="in">
                  <span v-if="branch.newRow.IN"><i class="fa-solid fa-circle-down"></i>{{ showHHMM(branch.newRow.IN) }}出勤</span>
                </span>
                <span class="out">
                  <span v-if="branch.newRow.OUT">退勤{{ showHHMM(branch.newRow.OUT) }}<i class="fa-solid fa-circle-up"></i></span>
                </span>
              </div>
            </div>
            <div class="total">
              --
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!--▼勤務時間選択-->
    <div id="editlog" :class="{ active: editMode, existing: slot.IN || slot.OUT }">
      <input type="time" v-model="slot.IN" @click.stop>
      <span><i class="fa-solid fa-caret-right"></i></span>
      <input type="time" v-model="slot.OUT" @click.stop>
      <button
        @click.stop="setLogDaily"
        title="適用"
      >適用</button>
      <button
        @click.stop="deleteLogDaily"
        title="削除"
        class="delete"
        v-if="slot.IN || slot.OUT"
      >削除</button>
      <button
        @click.stop="deactivateEditMode"
        class="cancel"
      ><i class="fa-solid fa-xmark"></i></button>
    </div>
  </div>
</template>

<script>
import DateSelect from '@/components/DateSelect.vue'
import Mixin from '@/mixins/utility.js'
import LogControl from '@/mixins/logControl.js'
export default {
  mixins: [Mixin, LogControl],
  components: { DateSelect },
  data () {
    return {
      list: [],
      status: false,
      staffList: this.$store.getters['Owner/staffList'],
      branchList: this.$store.getters['Owner/branchList']
    }
  },
  mounted () {
    this.$store.commit('pageTitle', '日次勤務状況 | Kintai Watch勤怠管理')
    if (this.$store.getters['Auth/token'] !== '' && this.$store.getters['Auth/token'] !== undefined) {
      this.$store.dispatch('Owner/loadData')
      this.$store.dispatch('Owner/loadStaffList')
      this.$store.dispatch('Owner/loadBranchList')
    }
    // Token
    this.$store.watch(
      (state, getters) => getters['Auth/token'],
      (newValue) => {
        this.$store.dispatch('Owner/loadData')
        this.$store.dispatch('Owner/loadStaffList')
        this.$store.dispatch('Owner/loadBranchList')
      }
    )
    this.$store.watch(
      (state, getters) => getters.companyName,
      (newValue) => {
        this.$store.commit('pageTitle', newValue)
      }
    )
    this.$store.watch(
      (state, getters) => getters['Log/list'],
      (newValue) => {
        this.list = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Owner/staffList'],
      (newValue) => {
        this.staffList = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Owner/branchList'],
      (newValue) => {
        this.branchList = newValue
      }
    )
  },
  methods: {
    //
    // 再読み込み
    reload () {
      this.$store.dispatch('Log/loadDailyLog', {
        slug: this.$store.getters.slug,
        ymd: this.$store.getters.ymd
      })
    }
  },
  computed: {
    //
    // ブランチごとの出勤
    attendanceList () {
      return (branchId) => {
        return this.list.filter(el => el.cat === branchId)
      }
    },
    //
    // スタッフリストから出勤済みのスタッフを除いたもの
    reducedStaffList () {
      return (stafflist) => {
        const attendance = this.list.map(el => el.staffId)
        return stafflist.filter(el => attendance.indexOf(el.id) === -1)
      }
    }
  },
  watch: {
  }
}
</script>
