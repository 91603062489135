import axios from 'axios'
// import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/kintaiwatch/WP'

const state = {
  clientData: {},
  branchList: [],
  staffList: []
}

const getters = {
  clientData: (state) => state.clientData,
  branchList: (state) => state.branchList,
  staffList: (state) => state.staffList
}

const mutations = {
  clientData (state, clientData) {
    state.clientData = clientData
  },
  branchList (state, branchList) {
    state.branchList = branchList
  },
  staffList (state, staffList) {
    state.staffList = staffList
  }
}

const actions = {
  //
  // 企業情報取得
  async loadData ({ rootGetters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/kw2/v1/client/' + rootGetters.slug)
        .then((response) => {
          console.log(response.data)
          commit('companyName', response.data, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ブランチ取得
  async loadBranchList ({ rootGetters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/kw2/v1/branch/' + rootGetters.slug)
        .then((response) => {
          console.log(response.data)
          commit('branchList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ブランチ追加
  async addBranch ({ rootGetters, commit }, branchTitle) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/branch/', {
          slug: rootGetters.slug,
          catName: branchTitle
        })
        .then((response) => {
          console.log(response.data)
          commit('branchList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ブランチ更新
  async updateBranch ({ rootGetters, commit, dispatch }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/branch/', {
          slug: rootGetters.slug,
          catid: data.catid,
          title: data.title
        })
        .then((response) => {
          console.log(response.data)
          commit('branchList', response.data)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 400) {
            alert('既に存在するブランチ名です')
            dispatch('loadBranchList')
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ブランチ削除
  async deleteBranch ({ rootGetters, commit }, catid) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/kw2/v1/branch/delete', {
          slug: rootGetters.slug,
          catid: catid
        })
        .then((response) => {
          console.log(response.data)
          commit('branchList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフリスト取得
  async loadStaffList ({ rootGetters, commit }) {
    console.log('try get staff list')
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/kw2/v1/staff/' + rootGetters.slug)
        .then((response) => {
          console.log(response.data)
          commit('staffList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ更新
  async updateStaff ({ rootGetters, commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/kw2/v1/staff/', {
          slug: rootGetters.slug,
          data: data
        })
        .then((response) => {
          console.log(response.data)
          // commit('staffList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ削除
  // 過去の記録を残すため実際には削除しない
  async deleteStaff ({ getters, commit }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .delete('/wp-json/kw2/v1/staff/' + id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          const newList = getters.staffList.filter(el => el.id !== id)
          commit('staffList', newList)
        })
    })
  }
}

const Owner = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Owner
