<template>
  <div>
    <header>
    <div>
      <div class="title">
        <h1><router-link to="/">Kintai Watch<i>easy</i></router-link></h1>
        <span class="conpanyName">{{ companyName }}</span>
      </div>
      <div class="optionals">
        <router-link to="/branch" v-if="domain"><i class="fa-solid fa-folder-tree"></i>ブランチ管理</router-link>
        <router-link to="/login"><i class="fa-solid fa-arrow-right-from-bracket"></i><span v-text="$route.name === 'login' ? 'ログイン': 'ログアウト'"></span></router-link>
      </div>
    </div>
    <nav v-if="domain">
      <ul>
        <li><router-link :to="{ name: 'OwnerDashboard', params: { dom: domain } }">日次勤務状況</router-link></li>
        <li><router-link :to="{ name: 'AttendanceLog' }">月次集計</router-link></li>
        <li><router-link :to="{ name: 'StaffList' }">スタッフリスト</router-link></li>
        <li><router-link :to="{ name: 'ShowQR' }"><i class="fa-solid fa-qrcode"></i>QRコード</router-link></li>
      </ul>
    </nav>
  </header>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: '',
      companyName: '',
      ready: false
    }
  },
  mounted () {
    // if (this.$store.getters['Auth/authData'] !== null && this.$store.getters['Auth/token'] === '') {
    //   this.$store.dispatch('Auth/login')
    // }
    // if (this.$store.getters['Auth/token'] === '' || this.$store.getters['Auth/token'] === undefined) {
    //   this.$router.push('/login')
    // }
    // Pagetitle
    this.$store.watch(
      (state, getters) => getters.pageTitle,
      (newValue) => {
        document.title = newValue
      }
    )
    // Token
    this.$store.watch(
      (state, getters) => getters['Auth/token'],
      (newValue) => {
        this.ready = true
      }
    )
    this.$store.watch(
      (state, getters) => getters.slug,
      (newValue) => {
        this.domain = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.companyName,
      (newValue) => {
        this.companyName = newValue
      }
    )
    // Processing表示
    this.$store.watch(
      (state, getters) => getters.processing,
      (newValue) => {
        this.processing = newValue
        const body = document.querySelector('body')
        if (newValue) {
          body.classList.add('processing')
        } else {
          body.classList.remove('processing')
        }
      }
    )
  },
  methods: {
    logout () {
      this.$store.commit('Auth/token', '')
      this.$router.push('/login')
    },
    returnHome () {
      this.$router.push('/')
    }
  },
  watch: {
  }
}
</script>
