import { createStore } from 'vuex'
import Auth from './auth.js'
import Admin from './admin.js'
import Owner from './owner.js'
import Log from './log.js'

export default createStore({
  state: {
    pageTitle: '',
    ready: false,
    processing: false,
    currentBlogId: '',
    companyName: '',
    slug: '',
    ymd: ''
  },
  getters: {
    pageTitle: (state) => state.pageTitle,
    ready: (state) => state.ready,
    processing: (state) => state.processing,
    currentBlogId: (state) => state.currentBlogId,
    companyName: (state) => state.companyName,
    slug: (state) => state.slug,
    ymd: (state) => state.ymd
  },
  mutations: {
    pageTitle (state, pageTitle) {
      state.pageTitle = pageTitle
    },
    ready (state, ready) {
      state.ready = ready
    },
    processing (state, processing) {
      state.processing = processing
    },
    currentBlogId (state, currentBlogId) {
      state.currentBlogId = currentBlogId
    },
    companyName (state, companyName) {
      state.companyName = companyName
    },
    slug (state, slug) {
      state.slug = slug
    },
    ymd (state, ymd) {
      state.ymd = ymd
    }
  },
  actions: {
    clearData ({ commit }) {
      commit('currentBlogId', '')
      commit('companyName', '')
      commit('slug', '')
    }
  },
  modules: {
    Auth,
    Admin,
    Owner,
    Log
  }
})
