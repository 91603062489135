<template>
  <div id="logheader" class="dateSelect">
    <div class="left">
      <h1>{{ monthDateStr }}</h1>
      <button
        @click="$emit('reload')"
      ><i class="fa-solid fa-arrows-rotate"></i>最新の情報に更新</button>
    </div>
    <div class="control">
      <div class="prev" @click="dateSetPrev"><i class="fa-solid fa-circle-left"></i>前日</div>
      <input
        type="date"
        v-model="YYMMDD"
        :max="getYYMMDDfromDate(exactToday)"
        @change="updateDate"
      >
      <div class="next" @click="dateSetNext">翌日<i class="fa-solid fa-circle-right"></i></div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixins/utility.js'
export default {
  mixins: [Mixin],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    updateDate (e) {
      this.YYMMDD = e.target.value
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
